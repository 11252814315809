import React, {useState,useEffect} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import Helmet from "react-helmet"
import $ from 'jquery'
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";


/**
 * Components
 */
import RowImgText from '@components/row-img-text/row-img-text';
import ReviewLine from '@components/review-line/review-line';

const HomeRowImgText = (props) => {
    const [propItems, setPropItems] = useState(false)
    const getitems = async url => {
      try {
        const { data } = await axios.get(url, {})
        setPropItems(data)
      } catch (error) {
        console.error(error)
      }
    }

    
  useEffect(() => {
    var reviewkey = props.reviewkey
    if(reviewkey == '' || reviewkey == null) {
        reviewkey = 'qayro8ImsRiW2xXhxJoabXNVgLYnOG5flqiJ34WhdLQ7WtXOg1rs1Cos6B8E';
    }
    let url = "https://www.theestas.com/api/widget?api_token="+reviewkey+"&with_comments=true&demo=0"; // TODO: base URL supposed to be from .env

    getitems(url)
  },[])
   

    
    const data = useStaticQuery(graphql`
        query {
        img3: file(relativePath: { eq: "img3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reviews: file(relativePath: { eq: "reviews-purple.png" }) {
            childImageSharp {
                fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);
    

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    adaptiveHeight: true,
                }
            }
        ]
    };



    return (
        <RowImgText>
            <Col md="12" lg="5" className="image-holder">
                <div className="image">
                    <Img fluid={data.img3.childImageSharp.fluid} alt="Estats Reviews - Anthony Pepe" />
                </div>
            </Col>
            <Col md="12" lg={{
                offset: 1,
                span: 6
            }}>
                
                <ReviewLine>
                    <h3>{propItems.rating}% Customer Service Rating</h3>
                    <small>
                    <a target="_blank" href={`${propItems.reviewUrl}/`}>
                        Based on {propItems.reviewCount} Reviews
                    </a>
                    </small>
                </ReviewLine>
                <a target="_blank" className="btn all-reviews" href={`${propItems.reviewUrl}/`}>See all Reviews</a>
                <div className="slider-holder">
                <Slider {...settings}>
                    {propItems && propItems.comments.map((item, key) => {
                        return<>
                        <div className="review-wrap">
                            <p>{item.text.substring(0,200)}{item.text.length>200?'...':''}
                            </p>
                            <h6>{item.date}</h6>
                            <h5>{item.author} ({item.type})</h5>
                        </div>
                        </>
                    })}
                </Slider>
                </div>
            </Col>


        </RowImgText>
    );
}

export default HomeRowImgText
